import * as React from 'react';

import MainLayout from '../components/Layout/MainLayout';
import HeaderSchedulePage from '../shared/Header/HeaderSchedulePage';
import MySEO from '../shared/MySEO';
import {
  ContentSection,
  NormalText,
  PageBanner,
  PageContainer,
  PageContent,
  SocialLink,
  SubTitle,
  Title
} from 'shared/PrivacyPolicy/Common';

const CacDieuKhoanVaDieuKienPage = ({ location }) => {
  return (
    <MainLayout
      noHeader
      renderCustomHeader={() => <HeaderSchedulePage />}
      hideSideCTA
      showFooterRegister={false}
    >
      <MySEO
        title="Điều khoản sử dụng - www.dolenglish.vn"
        h1="Các điều khoản và điều kiện"
        slug={location.pathname}
      />

      <PageContainer>
        <PageBanner
          breadcrumbLinks={[
            {
              text: 'DOL English',
              to: '/'
            },
            {
              text: 'Các điều khoản và điều kiện',
              to: ''
            }
          ]}
          title="Các điều khoản và điều kiện"
          description="Vui lòng đọc kỹ các điều khoản và điều kiện này trước khi sử dụng Dịch vụ của Chúng tôi."
          subText="Latest Update: ngày 11 tháng 12 năm 2020"
        />
        <PageContent>
          <ContentSection hasBottomDivider>
            <Title>Giải thích và Định nghĩa</Title>
            <SubTitle>Diễn dịch</SubTitle>
            <NormalText>
              Những từ có chữ cái đầu tiên được viết hoa có nghĩa được xác định
              trong các điều kiện sau. Các định nghĩa sau đây sẽ có cùng ý nghĩa
              bất kể chúng xuất hiện ở số ít hay số nhiều.
            </NormalText>

            <SubTitle>Các định nghĩa</SubTitle>
            <NormalText>
              Vì mục đích của các Điều khoản và Điều kiện này:
            </NormalText>
            <ul>
              <li>
                <NormalText>
                  <strong>Đơn vị liên kết:</strong> có nghĩa là một tổ chức kiểm
                  soát, chịu sự kiểm soát của hoặc dưới sự kiểm soát chung của
                  một bên, trong đó “quyền kiểm soát” có nghĩa là quyền sở hữu
                  từ 50% trở lên cổ phần, lãi vốn cổ phần hoặc các chứng khoán
                  khác được quyền bỏ phiếu bầu giám đốc hoặc cơ quan quản lý
                  khác .
                </NormalText>
              </li>
              <li>
                <NormalText>
                  <strong>Quốc gia đề cập đến: </strong>New York, Hoa Kỳ
                </NormalText>
              </li>
              <li>
                <NormalText>
                  <strong>Công ty </strong>(được gọi là “Công ty”, “Chúng tôi”,
                  “Chúng tôi” hoặc “Của chúng tôi” trong Thỏa thuận này) đề cập
                  đến DOL IELTS Đình Lực, 458/14 Hẻm 458 Ba Tháng Hai, Phường
                  12, Quận 10, Thành phố Hồ Chí Minh 700000, Việt Nam.
                </NormalText>
              </li>
              <li>
                <NormalText>
                  <strong>Thiết bị </strong>có nghĩa là bất kỳ thiết bị nào có
                  thể truy cập Dịch vụ như máy tính, điện thoại di động hoặc máy
                  tính bảng kỹ thuật số.
                </NormalText>
              </li>
              <li>
                <NormalText>
                  <strong>Dịch vụ </strong>đề cập đến Trang web.
                </NormalText>
              </li>
              <li>
                <NormalText>
                  <strong>Điều khoản và Điều kiện </strong>(còn được gọi là
                  “Điều khoản”) có nghĩa là các Điều khoản và Điều kiện này tạo
                  thành toàn bộ thỏa thuận giữa Bạn và Công ty về việc sử dụng
                  Dịch vụ.
                </NormalText>
              </li>
              <li>
                <NormalText>
                  <strong>Dịch vụ truyền thông xã hội của bên thứ ba </strong>có
                  nghĩa là bất kỳ dịch vụ hoặc nội dung nào (bao gồm dữ liệu,
                  thông tin, sản phẩm hoặc dịch vụ) do bên thứ ba cung cấp có
                  thể được hiển thị, bao gồm hoặc cung cấp bởi Dịch vụ.
                </NormalText>
              </li>
              <li>
                <NormalText>
                  <strong>Trang web </strong>đề cập đến Hệ sinh thái học Tiếng
                  Anh Tư duy dành cho người Việt, có thể truy cập từ{' '}
                  <SocialLink href="https://www.dolenglish.vn/" target="_blank">
                    https://www.dolenglish.vn/
                  </SocialLink>
                </NormalText>
              </li>
              <li>
                <NormalText>
                  <strong>Bạn </strong>có nghĩa là cá nhân truy cập hoặc sử dụng
                  Dịch vụ, hoặc công ty hoặc pháp nhân khác thay mặt cho cá nhân
                  đó đang truy cập hoặc sử dụng Dịch vụ, nếu có.
                </NormalText>
              </li>
            </ul>
          </ContentSection>

          <ContentSection hasBottomDivider>
            <Title>Nhìn nhận</Title>
            <NormalText>
              Đây là các Điều khoản và Điều kiện chi phối việc sử dụng Dịch vụ
              này và thỏa thuận hoạt động giữa Bạn và Công ty. Các Điều khoản và
              Điều kiện này quy định các quyền và nghĩa vụ của tất cả người dùng
              về việc sử dụng Dịch vụ.
            </NormalText>
            <NormalText>
              Quyền truy cập và sử dụng Dịch vụ của bạn tùy thuộc vào việc Bạn
              chấp nhận và tuân thủ các Điều khoản và Điều kiện này. Các Điều
              khoản và Điều kiện này áp dụng cho tất cả khách truy cập, người
              dùng và những người khác truy cập hoặc sử dụng Dịch vụ.
            </NormalText>
            <NormalText>
              Bằng cách truy cập hoặc sử dụng Dịch vụ, bạn đồng ý bị ràng buộc
              bởi các Điều khoản và Điều kiện này. Nếu Bạn không đồng ý với bất
              kỳ phần nào của Điều khoản và Điều kiện này, thì bạn có thể rời
              khỏi website
            </NormalText>
            <NormalText>
              Việc bạn truy cập và sử dụng Dịch vụ cũng tùy thuộc vào việc Bạn
              chấp nhận và tuân thủ Chính sách Bảo mật của Công ty. Chính sách
              Bảo mật của Chúng tôi mô tả các chính sách và thủ tục của Chúng
              tôi về việc thu thập, sử dụng và tiết lộ thông tin cá nhân của Bạn
              khi Bạn sử dụng Ứng dụng hoặc Trang web và cho Bạn biết về quyền
              riêng tư của Bạn và cách luật bảo vệ Bạn. Vui lòng đọc kỹ Chính
              sách Bảo mật của Chúng tôi trước khi sử dụng Dịch vụ của Chúng
              tôi.
            </NormalText>
          </ContentSection>

          <ContentSection hasBottomDivider>
            <Title>Liên kết đến các trang web khác</Title>
            <NormalText>
              Dịch vụ của chúng tôi có thể chứa các liên kết đến các trang web
              hoặc dịch vụ của bên thứ ba không thuộc sở hữu hoặc kiểm soát của
              Công ty.
            </NormalText>
            <NormalText>
              Công ty không có quyền kiểm soát và không chịu trách nhiệm đối với
              nội dung, chính sách bảo mật hoặc thông lệ của bất kỳ trang web
              hoặc dịch vụ nào của bên thứ ba. Bạn cũng thừa nhận và đồng ý rằng
              Công ty sẽ không chịu trách nhiệm hoặc nghĩa vụ trực tiếp hoặc
              gián tiếp đối với bất kỳ thiệt hại hoặc mất mát nào gây ra hoặc bị
              cáo buộc là do hoặc liên quan đến việc sử dụng hoặc dựa vào bất kỳ
              nội dung, hàng hóa hoặc dịch vụ nào như vậy có sẵn trên hoặc thông
              qua bất kỳ trang web hoặc dịch vụ nào như vậy.
            </NormalText>
            <NormalText>
              Chúng tôi đặc biệt khuyên Bạn nên đọc các điều khoản và điều kiện
              và chính sách bảo mật của bất kỳ trang web hoặc dịch vụ của bên
              thứ ba nào mà Bạn truy cập.
            </NormalText>
          </ContentSection>

          <ContentSection hasBottomDivider>
            <Title>Chấm dứt</Title>
            <NormalText>
              Khi chấm dứt, quyền sử dụng Dịch vụ của Bạn sẽ chấm dứt ngay lập
              tức.
            </NormalText>
          </ContentSection>

          <ContentSection hasBottomDivider>
            <Title>Trách nhiệm hữu hạn</Title>
            <NormalText>
              Nội dung của Trang web của chúng tôi, chẳng hạn như văn bản, đồ
              họa, hình ảnh, thông tin và các tài liệu khác có trên trang web
              giới thiệu các thông tin liên quan đến khóa học tại DOL IELTS Đình
              Lực và Thư viện các bài viết chia sẻ kinh nghiệm học Tiếng Anh.
              Các phần nội dung văn bản liên quan đến các vấn đề chính trị, tôn
              giáo, sắc tộc (nếu có) chỉ thuần túy mang tính chất thông tin và
              học thuật, không nhằm đả kích cá nhân hay tổ chức nào.
            </NormalText>
            <NormalText>
              Trong phạm vi tối đa được luật pháp hiện hành cho phép, trong mọi
              trường hợp, Công ty hoặc các nhà cung cấp của Công ty sẽ không
              chịu trách nhiệm pháp lý đối với bất kỳ thiệt hại đặc biệt, ngẫu
              nhiên, gián tiếp hoặc do hậu quả nào (bao gồm, nhưng không giới
              hạn, thiệt hại do mất lợi nhuận, mất dữ liệu hoặc thông tin khác,
              do gián đoạn kinh doanh, thương tích cá nhân, mất quyền riêng tư
              phát sinh từ hoặc theo bất kỳ cách nào liên quan đến việc sử dụng
              hoặc không thể sử dụng Dịch vụ, phần mềm của bên thứ ba và / hoặc
              phần cứng của bên thứ ba được sử dụng với Dịch vụ, hoặc nếu không
              liên quan đến bất kỳ điều khoản nào của Điều khoản này), ngay cả
              khi Công ty hoặc bất kỳ nhà cung cấp nào đã được thông báo về khả
              năng xảy ra những thiệt hại đó và ngay cả khi việc khắc phục không
              đạt được mục đích thiết yếu của nó.
            </NormalText>
            <NormalText>
              Một số tiểu bang không cho phép loại trừ các bảo đảm ngụ ý hoặc
              giới hạn trách nhiệm đối với các thiệt hại ngẫu nhiên hoặc do hậu
              quả, có nghĩa là một số giới hạn trên có thể không được áp dụng. Ở
              những tiểu bang này, trách nhiệm của mỗi bên sẽ được giới hạn ở
              mức độ lớn nhất được pháp luật cho phép.
            </NormalText>
          </ContentSection>

          <ContentSection hasBottomDivider>
            <Title>Quyền sở hữu trí tuệ</Title>
            <NormalText>
              Bạn thừa nhận rằng Nội dung Dịch vụ của Chúng tôi tuân theo bản
              quyền và do đó Bạn phải đảm bảo rằng Nội dung chỉ được sử dụng cho
              mục đích nghiên cứu và / hoặc tham khảo của Bạn và không được sao
              chép, tái sản xuất, phát sóng lại hoặc khai thác thương mại toàn
              bộ hoặc bất kỳ phần nào.
            </NormalText>
          </ContentSection>

          <ContentSection hasBottomDivider>
            <Title>Tuyên bố từ chối trách nhiệm</Title>
            <NormalText>
              Dịch vụ được cung cấp cho Bạn “NGUYÊN TRẠNG” và “HIỆN CÓ” và với
              tất cả các lỗi và khuyết tật mà không có bất kỳ hình thức bảo hành
              nào. Trong phạm vi tối đa được phép theo luật hiện hành, Công ty,
              nhân danh chính mình và thay mặt cho các Chi nhánh của mình cũng
              như các nhà cấp phép và nhà cung cấp dịch vụ tương ứng của họ, từ
              chối rõ ràng tất cả các bảo đảm, dù rõ ràng, ngụ ý, theo luật định
              hay cách khác, đối với Dịch vụ, bao gồm tất cả các bảo đảm ngụ ý
              về khả năng bán được, tính phù hợp cho một mục đích cụ thể, quyền
              sở hữu và không vi phạm, và các bảo đảm có thể phát sinh trong quá
              trình giao dịch, quá trình thực hiện, sử dụng hoặc thực hành
              thương mại. Không giới hạn những điều đã nói ở trên, Công ty không
              cung cấp bảo hành hoặc cam kết nào và không tuyên bố dưới bất kỳ
              hình thức nào rằng Dịch vụ sẽ đáp ứng các yêu cầu của Bạn, đạt
              được bất kỳ kết quả dự kiến nào,
            </NormalText>
            <NormalText>
              Không giới hạn những điều đã nói ở trên, Công ty cũng như bất kỳ
              nhà cung cấp nào của công ty không đưa ra bất kỳ tuyên bố hoặc bảo
              đảm nào dưới bất kỳ hình thức nào, rõ ràng hay ngụ ý: (i) về hoạt
              động hoặc tính khả dụng của Dịch vụ, hoặc thông tin, nội dung và
              tài liệu hoặc sản phẩm bao gồm trên đó; (ii) rằng Dịch vụ sẽ không
              bị gián đoạn hoặc không có lỗi; (iii) về tính chính xác, độ tin
              cậy hoặc đơn vị tiền tệ của bất kỳ thông tin hoặc nội dung nào
              được cung cấp thông qua Dịch vụ; hoặc (iv) rằng Dịch vụ, máy chủ,
              nội dung hoặc e-mail được gửi từ hoặc thay mặt cho Công ty không
              có vi-rút, tập lệnh, trojan, sâu, phần mềm độc hại, bom hẹn giờ
              hoặc các thành phần có hại khác.
            </NormalText>
            <NormalText>
              Một số khu vực pháp lý không cho phép loại trừ một số loại bảo đảm
              hoặc giới hạn đối với các quyền theo luật định hiện hành của người
              tiêu dùng, vì vậy một số hoặc tất cả các loại trừ và giới hạn ở
              trên có thể không áp dụng cho Bạn. Nhưng trong trường hợp như vậy,
              các loại trừ và giới hạn quy định trong phần này sẽ được áp dụng ở
              mức độ lớn nhất có thể thực thi được theo luật hiện hành.
            </NormalText>
          </ContentSection>

          <ContentSection hasBottomDivider>
            <Title>Luật chi phối</Title>
            <NormalText>
              Luật pháp của Quốc gia, không bao gồm các quy tắc xung đột luật,
              sẽ chi phối Điều khoản này và việc bạn sử dụng Dịch vụ. Việc bạn
              sử dụng Dịch vụ cũng có thể tuân theo luật pháp địa phương, tiểu
              bang, quốc gia hoặc quốc tế khác.
            </NormalText>
          </ContentSection>

          <ContentSection hasBottomDivider>
            <Title>Giải quyết tranh chấp</Title>
            <NormalText>
              Nếu Bạn có bất kỳ mối quan tâm hoặc tranh chấp nào về Dịch vụ, Bạn
              đồng ý trước tiên cố gắng giải quyết tranh chấp một cách không
              chính thức bằng cách liên hệ với Công ty.
            </NormalText>
          </ContentSection>

          <ContentSection hasBottomDivider>
            <Title>Hiệu lực từng phần và Từ bỏ</Title>
            <SubTitle>Tính hiệu lực từng phần</SubTitle>
            <NormalText>
              Nếu bất kỳ điều khoản nào trong các Điều khoản này được coi là
              không thể thi hành hoặc không hợp lệ, điều khoản đó sẽ được thay
              đổi và giải thích để hoàn thành mục tiêu của điều khoản đó ở mức
              độ cao nhất có thể theo luật hiện hành và các điều khoản còn lại
              sẽ tiếp tục có hiệu lực đầy đủ.
            </NormalText>

            <SubTitle>Từ bỏ</SubTitle>
            <NormalText>
              Trừ khi được quy định ở đây, việc không thực hiện quyền hoặc yêu
              cầu thực hiện nghĩa vụ theo Điều khoản này sẽ không ảnh hưởng đến
              khả năng của một bên trong việc thực hiện quyền đó hoặc yêu cầu
              thực hiện như vậy vào bất kỳ thời điểm nào sau đó cũng như việc từ
              bỏ vi phạm sẽ không cấu thành sự từ bỏ của bất kỳ vi phạm tiếp
              theo.
            </NormalText>
          </ContentSection>

          <ContentSection hasBottomDivider>
            <Title>Phiên dịch</Title>
            <NormalText>
              Các Điều khoản và Điều kiện này có thể đã được dịch nếu Chúng tôi
              cung cấp chúng cho Bạn trên Dịch vụ của chúng tôi. Bạn đồng ý rằng
              văn bản gốc bằng tiếng Anh sẽ được ưu tiên trong trường hợp có
              tranh chấp.
            </NormalText>
          </ContentSection>

          <ContentSection hasBottomDivider>
            <Title>Các thay đổi đối với các Điều khoản và Điều kiện này</Title>
            <NormalText>
              Chúng tôi có quyền, theo quyết định riêng của mình, sửa đổi hoặc
              thay thế các Điều khoản này bất kỳ lúc nào. Nếu bản sửa đổi là
              quan trọng, Chúng tôi sẽ nỗ lực hợp lý để cung cấp thông báo ít
              nhất 30 ngày trước khi bất kỳ điều khoản mới nào có hiệu lực. Điều
              gì tạo nên sự thay đổi quan trọng sẽ được xác định theo quyết định
              riêng của Chúng tôi.
            </NormalText>
            <NormalText>
              Bằng cách tiếp tục truy cập hoặc sử dụng Dịch vụ của Chúng tôi sau
              khi những sửa đổi đó có hiệu lực, Bạn đồng ý bị ràng buộc bởi các
              điều khoản sửa đổi. Nếu Bạn không đồng ý với các điều khoản mới,
              toàn bộ hoặc một phần, vui lòng ngừng sử dụng trang web và Dịch
              vụ.
            </NormalText>
          </ContentSection>

          <ContentSection hasBottomDivider>
            <Title>Liên hệ chúng tôi</Title>
            <NormalText>
              Nếu bạn có bất kỳ câu hỏi nào về các Điều khoản và Điều kiện này,
              Bạn có thể liên hệ với chúng tôi qua email:{' '}
              <SocialLink href="mailto:social@dolenglish.vn" target="_blank">
                social@dolenglish.vn
              </SocialLink>
            </NormalText>
          </ContentSection>
        </PageContent>
      </PageContainer>
    </MainLayout>
  );
};

CacDieuKhoanVaDieuKienPage.propTypes = {};

CacDieuKhoanVaDieuKienPage.defaultProps = {};

export default React.memo(CacDieuKhoanVaDieuKienPage);
